import { createContext, Dispatch, FC, ReactNode, SetStateAction, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';

interface ContextProps {
  showError: Dispatch<SetStateAction<string | undefined>>;
  showSuccess: Dispatch<SetStateAction<string | undefined>>;
  showWarning: Dispatch<SetStateAction<string | undefined>>;
}
const ToastMessageContext = createContext<ContextProps>({
  showError: (string) => {},
  showSuccess: (string) => {},
  showWarning: (string) => {},
});

const ToastMessageProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [successMessage, setSuccessMessage] = useState<string | undefined>();
  const [warningMessage, setWarningMessage] = useState<string | undefined>();

  return (
    <ToastMessageContext.Provider
      value={{
        showError: setErrorMessage,
        showSuccess: setSuccessMessage,
        showWarning: setWarningMessage,
      }}
    >
      {children}
      {errorMessage && (
        <Snackbar
          open={Boolean(errorMessage)}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setErrorMessage(undefined)}
        >
          <Alert onClose={() => setErrorMessage(undefined)} severity='error' sx={{ width: '400px', maxWidth: '80%' }}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )}
      {successMessage && (
        <Snackbar
          open={Boolean(successMessage)}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setSuccessMessage(undefined)}
        >
          <Alert
            onClose={() => setSuccessMessage(undefined)}
            severity='success'
            sx={{ width: '400px', maxWidth: '80%' }}
          >
            {successMessage}
          </Alert>
        </Snackbar>
      )}
      {warningMessage && (
        <Snackbar
          open={Boolean(warningMessage)}
          autoHideDuration={4000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          onClose={() => setWarningMessage(undefined)}
        >
          <Alert
            onClose={() => setWarningMessage(undefined)}
            severity='warning'
            sx={{ width: '400px', maxWidth: '80%' }}
          >
            {warningMessage}
          </Alert>
        </Snackbar>
      )}
    </ToastMessageContext.Provider>
  );
};

export { ToastMessageContext, ToastMessageProvider };
